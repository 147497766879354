import React from "react";

import styles from '../../CommonTextBlock/CommonTextBlock.module.css';

class Education extends React.Component {
  render() {
    return (
      <div className={styles.history}>
        {/* <img className={styles.image} alt='картинка'/> */}
        <p className={styles.text}>ГАУ АО «ЦПАПР» активно сотрудничает 
        с образовательными учреждениями города. Ежегодно у нас проходят практику 
        или стажировку студенты Астраханского государственного университета 
        и Астраханского государственного архитектурно-строительного университета. 
        Сотрудники учреждения проводят лекции и семинары по вопросам управления 
        земельными ресурсами, территориального землеустройства, кадастра недвижимости и др.</p>
        <p className={styles.text}>Подготовка (повышение квалификации) руководителей и работников 
        гражданской обороны, органов управления единой государственной системы предупреждения и 
        ликвидации чрезвычайных ситуаций Астраханской области</p>
        <ul className={styles.list}>
          <li className={styles.listItem}>Организация и ведение гражданской обороны, предупреждение 
          и ликвидация чрезвычайных ситуаций в районах, муниципальных образованиях и организациях</li>
          <li className={styles.listItem}>Организация работы комиссий по предупреждению и ликвидации 
          чрезвычайных ситуаций и обеспечению пожарной безопасности</li>
          <li className={styles.listItem}>Организация и ведение гражданской обороны, предупреждения 
          и ликвидации чрезвычайных ситуаций для должностных лиц и специалистов органов 
          повседневного и постоянного управления РСЧС</li>
          <li className={styles.listItem}>Организация гражданской обороны и защиты от ЧС для 
          работников, уполномоченных на решение задач в области ГО и ЧС</li>
          <li className={styles.listItem}>Основы ГО и защиты от ЧС природного и техногенного 
          характера для руководителей и персонала ЕДДС</li>
          <li className={styles.listItem}>Организация, планирование и обеспечение эвакуационных мероприятий</li>
          <li className={styles.listItem}>Организация работы комиссий по повышению устойчивости 
          функционирования объектов экономики в мирное и военное время</li>
          <li className={styles.listItem}>Подготовка и применение спасательных служб и НАСФ 
          по обеспечению выполнения мероприятий ГО и защиты от ЧС</li>
          <li className={styles.listItem}>Безопасность жизнедеятельности в области ГО и 
          защиты от ЧС природного и техногенного характера</li>
        </ul>
      </div>
    )
  }
}

export default Education;
