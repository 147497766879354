import React from "react";

import styles from '../../CommonTextBlock/CommonTextBlock.module.css';

class Survey extends React.Component {
  render() {
    return (
      <div className={styles.history}>
        {/* <img className={styles.image} alt='картинка'/> */}
        <p className={styles.text}>Геодезические работы включают в себя комплекс мероприятий 
        по определению координат и высот точек земной поверхности и пространственных объектов. 
        Проведение геодезических работ позволяет получить комплексные данные о рельефе территории 
        и ситуации на местности, уточнить линейные размеры объектов и их характеристики, 
        что делает проведение данных работ необходимым условием при планировании, 
        строительстве и реконструкции объектов.</p>
        <ul className={styles.list}>Виды геодезических работ, выполняемых Центром: 
          <li className={styles.listItem}>топографо-геодезические работы - 
          съемка различных масштабов, обновление и создание топографических карт и планов, 
          аэрофотосъемки</li>
          <li className={styles.listItem}>кадастровые геодезические работы - 
          определение площади и границ земельных участков,  
          разбивка границ земельных участков на местности</li>
          <li className={styles.listItem}>контрольно-исполнительные съемки с целью выявления 
          геометрических отклонений от проекта, определения фактического положения 
          в плане и по высоте конструкций по завершению строительства</li>
          <li className={styles.listItem}>фасадная съемка вертикальных поверхностей зданий и сооружений</li>
          <li className={styles.listItem}>геодезическая экспертиза для суда</li>
        </ul>
        <p className={styles.text}>Специалисты ГАУ АО «ЦПАПР» проводят геодезические работы 
        с использованием современного оборудования, в том числе беспилотного летательного аппарата. 
        Используемое оборудование своевременно проходит процедуры поверки.</p>

      </div>
    )
  }
}

export default Survey;
