import React from "react";

import styles from '../../CommonTextBlock/CommonTextBlock.module.css';
// import r from '../../../images/1920px-Agnivo.jpg';

class Databases extends React.Component {
  render() {
    return (
      <div className={styles.history}>
        {/* <img className={styles.image} src={r} alt='картинка'/> */}
        <p className={styles.text}>Географические карты всегда ценились 
        как важнейший информационный ресурс. Стремительное развитие общества 
        привело к увеличению информации вокруг нас, и для её хранения и изучения 
        уже недостаточно бумажных карт. Для обработки столь объемного массива данных 
        рамки классической картографии расширяются возможностями компьютерных систем 
        и геоинформационных технологий.</p>
        <p className={styles.text}>Ведение информационных ресурсов и баз данных 
        является одним из основных видов деятельности учреждения и подразумевает 
        под собой проектирование и разработку специализированных баз данных, 
        а также формирование и наполнение геоинформационной системы Астраханской области. 
        Объединение множества типов данных в единой базе позволяет качественно 
        и в короткие сроки анализировать расположение объектов в пространстве, 
        определять их качественные и количественные характеристики, 
        отслеживать определенные закономерности. </p>
        <ul className={styles.list}>Пространственный анализ позволяет нам осуществлять следующую деятельность: 
          <li className={styles.listItem}>разработка предложений по определению границ 
          зон с особыми условиями использования территории</li>
          <li className={styles.listItem}>создание паспортов рыбоводных и рыболовных участков</li>
          <li className={styles.listItem}>выполнение запросов надзорных органов,  
          в том числе с целью выявления территорий, используемых не в соответствии с их целевым назначением</li>
          <li className={styles.listItem}>содействие органам исполнительной государственной власти 
          в процедуре согласования водных и земельных участков</li>
        </ul>
        <ul className={styles.list}>На счету нашего учреждения ряд выполненных проектов, 
        подготовленных с использованием материалов геоинформационной системы Астраханской области: 
          <li className={styles.listItem}>создание подсистемы геоинформационных сервисов 
          Системы обеспечения вызова экстренных оперативных служб по единому номеру "112"</li>
          <li className={styles.listItem}>подготовка адресных планов для проведения 
          мероприятий всероссийской переписи населения 2020</li>
          <li className={styles.listItem}>проведение инвентаризации гидротехнических сооружений</li>
        </ul>
      </div>
    )
  }
}

export default Databases;
