import React from "react";

// import StaffPhoto from '../../../images/employees/cpapr_staff.jpg';
import styles from '../../CommonTextBlock/CommonTextBlock.module.css';

class History extends React.Component {
  render() {
    return (
      <div className={styles.content}>
        {/* <img className={styles.image} src={StaffPhoto} alt='Коллектив организации'/> */}
        <p className={styles.text}><span className={styles.accent}>В 2002 году</span> Администрацией Астраханской области был создан 
          «Научно-исследовательский геоинформационный центр» (НИГИЦ) Астраханской области.</p>
        <p className={styles.text}>Основная цель Государственного предприятия — 
          анализ и визуализация геоинформационных материалов для принятия эффективных 
          административно-управленческих решений по стратегическим направлениям 
          развития экономики региона.</p>
        <p className={styles.text}>Основное направление деятельности — 
          работы по оказанию услуг в сфере геоинформационно-аналитического обеспечения 
          для управления пространственным развитием территорий; 
          использование результатов космической деятельности, 
          картографическое производство, наука и образование.</p>
        <p className={styles.text}><span className={styles.accent}>В 2012</span> ГАУ АО «НИГИЦ» стало государственным автономным учреждением 
          и перешло в подведомственность министерства строительства и дорожного хозяйства 
          Астраханской области.</p>
        <p className={styles.text}><span className={styles.accent}>В 2014 году</span>, в связи с наделением министерства промышленности, 
          транспорта и природных ресурсов Астраханской области полномочиями 
          по реализации Основ государственной политики в области развития космической деятельности, 
          ГАУ АО «НИГИЦ» перешло в подведомственность Минпрома АО.</p>
        <p className={styles.text}>Во исполнение Поручений Президента РФ В.В. Путина по вопросу повышения 
          эффективности использования результатов космической деятельности 
          в интересах модернизации экономики РФ и развития ее регионов, 
          в 2015 году на базе ГАУ АО «НИГИЦ» был создан Региональный Центр космических услуг (ЦКУ), 
          а ГАУ АО «НИГИЦ» наделено функциями ГИС-оператора по внедрению космических технологий 
          в интересах развития и модернизации экономики Астраханской области.</p>
        <p className={styles.text}>ЦКУ является основой практического использования результатов космической деятельности, 
          обеспечивающей эффективное использование ее результатов 
          в интересах социально-экономического и инновационного развития региона 
          на основе геоинформационных и web-портальных технологий.</p>
        <p className={styles.text}>В 2017 году постановлением Правительства Астраханской области 
        было поручено создать фонд пространственных данных Астраханской области и определить ГАУ АО «НИГИЦ» его фондодержателем.</p>
        <p className={styles.text}>В 2019 году учреждение приобрело статус оператора 
        региональной навигационно-информационной системы (РНИС), расширило штат сотрудников 
        отделом мониторинга транспорта и утвердило новый устав. В том же году «НИГИЦ» 
        был переименован в «Центр пространственной аналитики и промышленного развития».</p>
        <p className={styles.text}>С октября  в ГАУ АО «ЦПАПР» создано новое структурное подразделение УМЦ по ГО и ЧС, 
        в  котором осуществляется подготовка руководителей и работников гражданской обороны, 
        органов управления единой государственной системы предупреждения и ликвидации чрезвычайных ситуаций 
        Астраханской области.</p>
      </div>
    )
  }
}

export default History;
