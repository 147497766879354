import React from "react";

import styles from '../../CommonTextBlock/CommonTextBlock.module.css';

class Cadaster extends React.Component {
  render() {
    return (
      <div className={styles.history}>
        {/* <img classыName={styles.image} alt='картинка'/> */}
        <p className={styles.text}>Значимое место в спектре услуг учреждения занимает 
        осуществление землеустроительных и кадастровых работ. Кадастровые работы — 
        ключевой этап формирования земельных участков и объектов капитального строительства.</p>
        <ul className={styles.list}>Квалифицированные специалисты могут предложить Вам помощь 
        в решении следующих задач: 
          <li className={styles.listItem}>выполнение землеустроительных работ 
          по подготовке карты (плана) объекта землеустройства, заявления в виде файла в формате XML 
          в отношении территорий субъектов Российской Федерации, территорий муниципальных образований, 
          территорий населенных пунктов, территориальных зон, зон с особыми условиями использования 
          территорий с целью описания местоположения их границ, установления границ на местности, в иных целях</li>
          <li className={styles.listItem}>выполнение кадастровых работ по подготовке межевых планов, 
          технических планов и актов обследования объектов недвижимости</li>
          <li className={styles.listItem}>составления проектов межевания земельных участков 
          в отношении земельных участков, выделяемых в счет долей из земельных участков 
          общей долевой собственности из земель сельскохозяйственного назначения, 
          а также составление схем земельных участков в отношении земельных участков, 
          предоставляемых из земель государственной или муниципальной собственности</li>
        </ul>
      </div>
    )
  }
}

export default Cadaster;
